@import url("https://fonts.googleapis.com/css?family=Kanit&display=swap");

.signIn {
  width: 343px;
  height: 20px;
  top: 0px;
  font-size: 12px;
  padding-left: 10px;
  margin: auto;
  color: #ffffff;
  opacity: 0.7;
  font-weight: 400;
}

.signUp {
  text-decoration: none;
  color: #fdc934;
  opacity: 0.7;
  font-weight: 600;
}

.registerLink {
  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  cursor: pointer;
  margin: auto;
  padding: 3px;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 18px;
}
.MuiSvgIcon-root {
  width: 0.8em !important;
}
.MuiOutlinedInput-input {
  color: #fff !important;
}

.forgotPass {
  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;
  color: #fdc934;
  opacity: 0.7;
}
.checkbox {
  background-color: #200e32;
  left: 0%;
  right: 86.67%;
  top: 10%;
  bottom: 10%;
  opacity: 0.2;
  border: 1px solid #ffffff;
}
.remember-me {
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;
  font-family: Kanit;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: -0.3px;
  color: #ffffff;
  opacity: 0.7;
}
label {
  margin-bottom: 16px;
}
.input-frame {
  width: 343px;
  height: 56px;
  top: 361px;
  color: #ffffff;
  margin: auto;
  opacity: 0.6;
  display: block;
}
.input-box {
  background-color: #2a2d35;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding-left: 15px;
  margin-bottom: 10px;
}

.login-button {
  /* button/biru */
  display: block;
  cursor: pointer;

  /* Auto Layout */
  width: 343px;
  height: 48px;
  margin: auto;
  top: 563px;

  background-color: #fdc934;
  border-radius: 4px;
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: #252736;
}
.error {
  width: 343px;
  height: 20px;
  top: 361px;
  color: #fdc934;
  margin: auto;
  display: block;
  font-size: 12px;
}
.wrapper-new-password {
  display: flex;
  align-items: center;
  position: relative;
}
.wrapper-new-password .password-icon {
  color: #ffffff;
  opacity: 0.6;
  position: absolute;
  right: 8%;
}
.error-label-invisible {
  display: none;
}
.error-label {
  display: inline;
  color: #fdc934;
  margin: auto;
  font-size: 12px !important;
}
@media (min-width: 1px) and (max-width: 360px) {
  .wrapper-new-password .password-icon {
    right: 3%;
  }
}
@media (min-width: 361px) and (max-width: 400px) {
  .wrapper-new-password .password-icon {
    right: 5%;
  }
}
@media (min-width: 401px) and (max-width: 430px) {
  .wrapper-new-password .password-icon {
    right: 9%;
  }
}
@media (min-width: 431px) and (max-width: 460px) {
  .wrapper-new-password .password-icon {
    right: 14%;
  }
}
@media (min-width: 461px) and (max-width: 500px) {
  .wrapper-new-password .password-icon {
    right: 17%;
  }
}
@media (min-width: 501px) and (max-width: 530px) {
  .wrapper-new-password .password-icon {
    right: 19%;
  }
}
@media (min-width: 531px) and (max-width: 560px) {
  .wrapper-new-password .password-icon {
    right: 18%;
  }
}
@media (min-width: 561px) and (max-width: 600px) {
  .wrapper-new-password .password-icon {
    right: 22%;
  }
}
@media (min-width: 601px) and (max-width: 652px) {
  .wrapper-new-password .password-icon {
    right: 24%;
  }
}
@media (min-width: 653px) and (max-width: 693px) {
  .wrapper-new-password .password-icon {
    right: 26%;
  }
}
@media (min-width: 694px) and (max-width: 757px) {
  .wrapper-new-password .password-icon {
    right: 28%;
  }
}
@media (min-width: 758px) and (max-width: 834px) {
  .wrapper-new-password .password-icon {
    right: 28%;
  }
}
@media (min-width: 835px) and (max-width: 930px) {
  .wrapper-new-password .password-icon {
    right: 32%;
  }
}
@media (min-width: 931px) and (max-width: 1438px) {
  .wrapper-new-password .password-icon {
    right: 33%;
  }
}
@media (min-width: 1439px) {
  .wrapper-new-password .password-icon {
    right: 39%;
  }
}

