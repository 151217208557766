@import url('https://fonts.googleapis.com/css?family=Kanit&display=swap');


.termsAndPrivacy{
    color: #FFF;
    opacity: 0.7;
    font-weight: 600;
}
.termsLink{
    padding-top: 5%;
    font-family: Kanit;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    margin:auto;
    width: 343px;
    height: 20px;
    left: 24px;
    top: 0px;
    color: #a5a6a9;
}