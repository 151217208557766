@import url('https://fonts.googleapis.com/css?family=Kanit&display=swap');

.msgGamePaused {
    font-family: Kanit !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 140% !important;
    letter-spacing: 0.02em !important;
    color: #FFF !important;
}
.msgGamePausedSml {
    font-family: Kanit !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 140% !important;
    letter-spacing: 0.02em !important;
    color: rgba(255, 255, 255, 0.5) !important;
}