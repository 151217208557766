.makeStyles-root-1 {
  background: #2a2d35;
}
.MuiBottomNavigationAction-root.Mui-selected {
  color: #fdc934 !important;
}
.MuiBottomNavigationAction-root {
  color: grey !important;
}
.returnNum {
  padding-left: 5px;
  flex-direction: row;
}
.returnNumText {
  font-family: Kanit !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  /*line-height: 140%;*/
  padding-left: 5px !important;
  letter-spacing: 0.04em !important;
  color: #fff !important;
}
.oddsNum {
  font-family: Kanit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.04em;
  padding-left: 5px;
  color: #fdc934;
}
.returns {
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  left: 0;
  padding-right: 2px;
  color: rgba(255, 255, 255, 0.6);
  /*line-height: 140%;*/
  flex-direction: row;
  line-height: 22px;
}

.odds {
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 140%;
}
.dolarButton {
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.04em;
}

.betButton {
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  letter-spacing: 0.04em;
}
