@import url("https://fonts.googleapis.com/css?family=Kanit&display=swap");

.returnNumText {
  font-family: Kanit;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  padding-left: 5px;
  letter-spacing: 0.04em;
  color: #fff;
}

.betItem {
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  text-align: left;
/* or 22px */
  letter-spacing: 0.02em;
}

.betItemDismiss {
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: end;
/* or 22px */
  letter-spacing: 0.02em;
  text-transform: none;
  color: darkolivegreen;

}

.betItemSeeMore {
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20.38px;
  text-align: end;
/* or 22px */
  letter-spacing: 0.02em;
  text-transform: none;
  color: #bdd3fe;
}

.primtext{
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color:white;
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtext{
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
.delim{
  grid-area: 1/3/3/3;
  padding-top: 7px;
}
.score{
  font-family: Kanit;
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0.04em;
  text-align: center;
}
.score2{
    grid-area: 1/4/4/4;
}
.flag1{
  grid-area: 1/1/1/1;
}
.flag2{
  grid-area: 1/5/5/5;
}

.score{
  position: static;
  display:grid;
  grid-template-columns: auto;
  height: 46px;
  padding-top: 15px;
  padding-right: 5px;
}
.gameBet{
  padding-top: 48px;
  padding-right: 8px;
  text-align: right;
  font-family: Kanit;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
/* identical to box height, or 17px */
  letter-spacing: 0.1em;
  color: #FDC934;
}
.gameState{
  padding-top: 48px;
  text-align: left;
  font-family: Kanit;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */
  letter-spacing: 0.1em;
  color: #FDC934;
}
p{
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  /* identical to box height, or 14px */
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}
.scoreText{
  font-family: Kanit;
  padding-right: 40px;
  font-style: italic;
  font-weight: 800;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */
  text-align: center;
  letter-spacing: 0.04em;
  color: #FAFAFA;
}
.game{
  background-color: transparent;
}
.betList{
  flex:1;
  flex-direction: row;
  display: block;
  position: relative;
  width: 100%;
  top: 27px;
}

.gameList{
  display: block;
  position: relative;
  width: 100%;
  top: 27px;
}
.pad{
  padding-right: 4px;
}
.icon{
  position: relative;
  left: 18px;
  top: 26px;
}
.card:hover{
  transition-duration: 0.02s;
  background-color: #FDC934;
  color:rgba(0, 0, 0, 1);
}

.cardframe{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  position: absolute;
  width: 79px;
  height: 24px;
  right: 16px;
  top: 22px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */
  text-align: center;
  letter-spacing: 0.04em;
  color: #FDC934;
}

h4 {
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.7);
  height: 20px;
  font-size: 12px;
  padding-left: 10px;
  margin: auto;
  color: #ffffff;
  opacity: 0.7;
  font-weight: 400;
  position: absolute;
  height: 68px;
  left: 15px;
  right: 15px;
}
.betText{
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  /* or 22px */
  letter-spacing: 0.04em;
}

.quotaText{
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */
  text-align: center;
  letter-spacing: 0.04em;
}

.card {
  transition-duration: 0.3s;
  color: white;
  position: absolute;
  height: 68px;
  left: 15px;
  right: 15px;
  top: 27px;
  background: #2a2d35;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.registerLink {
  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;
  color: rgba(255, 255, 255, 0.6);
}

.forgotPass {
  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;
  color: #fdc934;
  opacity: 0.7;
}
.checkbox {
  background: #200e32;
  left: 0%;
  right: 86.67%;
  top: 10%;
  bottom: 10%;
  opacity: 0.2;
  border: 1px solid #ffffff;
}
.remember-me {
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;
  font-family: Kanit;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: -0.3px;
  color: #ffffff;
  opacity: 0.7;
}
label {
  margin-bottom: 16px;
}
.input-frame {
  width: 343px;
  height: 56px;
  top: 361px;
  color: #ffffff;
  margin: auto;
  opacity: 0.6;
  display: block;
}

.badge{
  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */
  text-align: center;
  letter-spacing: 0.04em;
  color: #FDC934;
}
.flag-wrapper {
  margin-left:auto;
  margin-right:auto;
  height:auto;
  width: 16%;
}
.team-logo {
  width: 100%;
  height: 50px;
  width:50px;
}
.img-wrapper {
  width: 100% !important;
}
.team-logo-raiders {
  height: 50px;
  width: 50px;
}
.wrapper {
  margin-left: auto;
  margin-right: auto;
  height: auto;
  width: 100%;
}
.primline {
  width: 100%;
  background-color: darkgrey;
  height: 1.5px;
  opacity: 0.4;
}

/*.primtext {
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}*/

.stack-top{
  z-index: 9;
  margin: 20px; /* for demo purpose  */
}

.ballpossession-indicator-left{
  width: 20%;
  height: 2px;
  background-color: orange;
  opacity: 8;
}
.ballpossession-indicator-right{
  width: 20%;
  height: 2px;
  background-color: orange;
  opacity: 8;
  float: right;
}
.odds-container {
  align-items: flex-end !important;
}
.betName {
  padding: 30px 10px 40px 30px !important;
}
.betNbaName {
  padding: 40px 10px 40px 30px !important;
}
.betOdd {
  /*padding:75px 40px 40px 20px !important;*/
  height: 44px;
}
.possessionCls {
  text-align: start;
  /*padding: 40px 0px 40px 60px !important;*/
}

.betNameSec {
  /*padding: 40px 30px 40px 40px !important;*/
  height: 74px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-left: 20px
}

.betOddBig {
  /*padding:40px 40px 40px 20px !important;*/
  width: 30%;
  height: 76px;
}

.betSmall {
  width: 100%;
  height: 32px;
}
