@import url("https://fonts.googleapis.com/css?family=Kanit&display=swap");

.delim{
    grid-area: 1/3/3/3;
    padding-top: 7px;
}
.score1{
    grid-area: 1/2/2/2;
}
.score2{
    grid-area: 1/4/4/4;
}
.flag1{
    grid-area: 1/1/1/1;
}
.flag2{
    grid-area: 1/5/5/5;
}
.center{
  position: absolute;
  left: 20.83%;
  right: 17.92%;
  top: 25%;
  bottom: 15%;
}
.center2{
  position: absolute;
  left: 20.83%;
  right: 17.92%;
  top: 50%;
  bottom: 15%;
}
.item1{
  grid-area: 1/1/1/1;
  text-align: center;
  position: relative;
}
.item2{
  grid-area: 1/2/2/2;
  position: relative;
  text-align: center;
}
.item3{
  text-align: center;
  position: relative;
  grid-area: 1/3/3/3;
}

.score{
    position: static;
    display:grid;
    grid-template-columns: auto;
    height: 46px;
    padding-top: 15px;
  /*  padding-left: 30px;*/
}
.gameBet{
    padding-top: 48px;
    padding-right: 8px;
    text-align: right;
    font-family: Kanit;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 140%;
/* identical to box height, or 17px */

letter-spacing: 0.1em;
text-transform: uppercase;

color: #FDC934;


}
.gameState{
    padding-top: 48px;
    text-align: left;
    font-family: Kanit;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */
    
    letter-spacing: 0.1em;
    text-transform: uppercase;
    
    color: #FDC934;
    

}
p{

font-family: Kanit;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 140%;
/* identical to box height, or 14px */

text-align: center;

color: rgba(255, 255, 255, 0.5);

}
.scoreText{
    font-family: Kanit;
    padding-right: 40px;
font-style: italic;
font-weight: 800;
font-size: 24px;
line-height: 140%;
/* identical to box height, or 34px */

text-align: center;
letter-spacing: 0.04em;

color: #FAFAFA;

}
.gameCard{
  margin-top: 40px;
  color: white;
  position: absolute;
  height: 110px;
  left: 15px;
  right: 15px;
  top: 27px;

  border-radius: 4px;
  background: #2a2d35;
}

.gameList{
    display: block;
    position: relative;
    width: 100%;
    top: 27px;

}
.pad{
    padding-right: 4px;
}
.icon{
    position: relative;
    left: 18px;
    top: 26px;
}
.card:hover{

transition-duration: 0.02s;
background-color: #FDC934;
color:rgba(0, 0, 0, 1);

}

.cardframe{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 8px;

position: absolute;
width: 64px;
height: 24px;
right: 16px;
top: 22px;

border: 1px solid rgba(255, 255, 255, 0.2);
box-sizing: border-box;
border-radius: 4px;

font-family: Kanit;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 140%;
/* identical to box height, or 17px */

text-align: center;
letter-spacing: 0.04em;

color: #FDC934;

}

h4 {
  font-family: Kanit;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  height: 20px;
  font-size: 12px;
  padding-left: 10px;
  margin: auto;
  color: #ffffff;
  opacity: 0.7;
  font-weight: 400;
  position: absolute;
  height: 68px;
  left: 15px;
  right: 15px;
}

h3{
    position: relative;
    left: 52px;
    bottom: 13px;
    
    font-family: Kanit;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    
    letter-spacing: 0.02em;
    
}

.card {
transition-duration: 0.3s;
  color: white;
  position: absolute;
  height: 68px;
  left: 15px;
  right: 15px;
  top: 27px;

  background: #2a2d35;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.registerLink {
  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;
  color: rgba(255, 255, 255, 0.6);
}

.forgotPass {
  font-family: Kanit;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 140%;
  text-align: right;
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;

  color: #fdc934;
  opacity: 0.7;
}
.checkbox {
  background: #200e32;
  left: 0%;
  right: 86.67%;
  top: 10%;
  bottom: 10%;

  opacity: 0.2;
  border: 1px solid #ffffff;
}
.remember-me {
  margin: auto;
  width: 343px;
  height: 20px;
  left: 24px;
  top: 0px;

  font-family: Kanit;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */
  letter-spacing: -0.3px;
  color: #ffffff;

  opacity: 0.7;
}
label {
  margin-bottom: 16px;
}
.input-frame {
  width: 343px;
  height: 56px;
  top: 361px;
  color: #ffffff;
  margin: auto;
  opacity: 0.6;
  display: block;
}

.input-box {
  background: #2a2d35;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.login-button {
  /* button/biru */
  display: block;

  /* Auto Layout */
  width: 343px;
  height: 48px;
  margin: auto;
  top: 563px;

  background: #fdc934;
  border-radius: 4px;

  font-family: Kanit;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.04em;

  color: #252736;
}
